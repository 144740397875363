import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import styles from "../../../styles/layout/BlockByTryingBeer/ByTryingBeer.module.scss";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Mousewheel,
  Thumbs,
} from "swiper/modules";
import {
  StraightPipes,
  StraightPipesSecond,
  DoublePipesMain,
} from "../../Widgets/WidgetsPipe/Pipes";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { dataSortBeer } from "./dataByTryingBeer";
import {
  CornerIcon,
  CornerIconOnSlider,
} from "../../Widgets/WidgetsPipe/Pipes";
// icon
import iconSlideLeftR from "../../../assets/icon/sliderBlock/arrow-backward.png";
import iconSlideRightR from "../../../assets/icon/sliderBlock/arrow-forward.png";

const ByTryingBeer = () => {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const swiperRef = useRef(null);
  const useRefIndex = useRef(0);
  const [swiper, setSwiper] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);

  const slideTo = (index) => swiper.slideTo(index);

  const LeftSliderButton = () => {
    return (
      <div style={{ zIndex: 3 }} className={styles.blockButtonSlide}>
        <button
          ref={navigationPrevRef}
          style={{
            backgroundColor: "transparent",
            cursor: "pointer",
            border: "none",
          }}
          onClick={() => swiperRef.current.slidePrev()}
        >
          <img src={iconSlideLeftR} alt={"iconSlideLeftR"} />
        </button>
      </div>
    );
  };

  const RightSliderButton = () => {
    return (
      <div style={{ zIndex: 3 }} className={styles.blockButtonSlide}>
        <button
          style={{
            backgroundColor: "transparent",
            cursor: "pointer",
            border: "none",
          }}
          ref={navigationNextRef}
          onClick={() => swiperRef.current.slideNext()}
        >
          <img src={iconSlideRightR} alt="iconSlideRightR" />
        </button>
      </div>
    );
  };

  const ContentSlider = ({ card }) => {
    return (
      <div className={styles.blockPhoto}>
        <div className={styles.blockPhotoSliderMain}>
          <img loading="lazy" alt={"imgPhotoSliderMain"} src={card?.photo} />
        </div>
        <div className={styles.blockSwiperSlideMiniContent}>
          <p className={styles.textSliderSwiperTitel}>
            {card?.titel.toLocaleUpperCase()}
          </p>
          <p className={styles.textSliderSwiperTitelRed}>
            {card?.titelRed.toLocaleUpperCase()}
          </p>
          <div className={styles.blockDiscriptSLider}>
            <p className={styles.textSliderSwiperDiscript}>{card?.discript}</p>
          </div>

          {/* <div className={styles.blockDiscriptSound}>
            <div>
              <img
                src={
                  "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/group_1171275846.png"
                }
                alt={"imgDiscriptSound"}
              />
            </div>
            <div className={styles.blockSoudSlider}>
              <p className={styles.textSoundSlider}>
                Запустите аудио, чтобы узнать легенду<pre></pre> создания
              </p>
            </div>
          </div> */}
        </div>
      </div>
    );
  };

  const updateButtonStyles = (activeIndex) => {
    dataSortBeer.forEach((_, index) => {
      const button = document.querySelector(`.tab_button_swiper_${index}`);
      if (button) {
        if (index === activeIndex) {
          button.style.backgroundColor = "#E6702D";
          button.style.color = "#fff";
        } else {
          button.style.backgroundColor = "";
          button.style.color = "#E6702D";
          button.style.border = "2px solid #E6702D";
        }
      }
    });
  };

  const ButtonSliderSwitch = () => {
    return (
      <div className={styles.contentButtonSlider}>
        {dataSortBeer.map((elem, indexX) => (
          <button
            key={indexX}
            onClick={() => swiperRef.current.slideTo(indexX)}
            style={{
              border: "2px solid #E6702D",
              color: "#E6702D",
            }}
            className={
              styles.buttonSliderSwitchS + " tab_button_swiper_" + indexX
            }
          >
            {elem?.titel.toLocaleUpperCase()}
          </button>
        ))}
      </div>
    );
  };

  const BlockAllBeerMobileV = () => {
    return (
      <div className={styles.blockMobileWrapper}>
        {dataSortBeer.map((elem, index) => (
          <div key={index} className={styles.contentBeer}>
            <div className={styles.photoBeerMobileSize}>
              <img src={elem.photo} alt={"imgPhotoBeerMobileSize"} />
            </div>
            <div className={styles.blockTextBeerSliderMobile}>
              <div className={styles.blockTitelSliderAddCorner}>
                <CornerIconOnSlider />
                <p className={styles.textSliderSwiperTitel}>
                  {elem.titel.toLocaleUpperCase()}
                </p>
              </div>

              <p className={styles.textSliderSwiperTitelRed}>
                {elem.titelRed.toLocaleUpperCase()}
              </p>
              <div className={styles.blockDiscriptMobile}>
                <p className={styles.textSliderSwiperDiscript}>
                  {elem.discript}
                </p>
              </div>

              {/* <div className={styles.blockDiscriptSound}>
                <img
                  src={
                    "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/group_1171275846.png"
                  }
                />
                <p className={styles.textSoundSlider}>
                  Запустите аудио, чтобы узнать легенду создания
                </p>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const BlockSlider = () => {
    return (
      <div className={styles.wrapperSlider}>
        <div className={styles.blockSliderContent}>
          <LeftSliderButton />
          <Swiper
            modules={[Navigation, Mousewheel, Pagination, Scrollbar, A11y]} //Thumbs
            direction="horizontal"
            slidesPerView={1}
            loop={true}
            onSlideChange={(swiper) => updateButtonStyles(swiper.realIndex)}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            spaceBetween={24}
            speed={600}
            className={styles.blockSliderSwiperContent}
            initialSlide={activeIndex}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
          >
            {dataSortBeer.map((card, index) => (
              <SwiperSlide key={index}>
                <ContentSlider card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
          <RightSliderButton />
        </div>

        <ButtonSliderSwitch />
      </div>
    );
  };

  return (
    <section className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h2 className={styles.textTitel}>
          {"Пробуйте легенды".toLocaleUpperCase()}
        </h2>
      </div>
      <BlockSlider />
      <BlockAllBeerMobileV />
    </section>
  );
};

export { ByTryingBeer };
