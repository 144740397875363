import styles from "../../../styles/layout/BlockMakingBeer/MakingBeer.module.scss";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
import { discript } from "./dataMakingBeer";
import { useRef, useState } from "react";
// import iconBeerCorner from "../../../../public/img/Tubes/beer/Vector 289.png";

const MakingBeer = () => {
  const containerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsScrolling(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsScrolling(false);
  };

  const handleMouseUp = () => {
    setIsScrolling(false);
  };

  const handleMouseMove = (e) => {
    if (!isScrolling) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 1;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <section id="beer" className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h2 className={styles.textTitel}>
          {"Узнайте, как мы варим пиво".toLocaleUpperCase()}
        </h2>
      </div>
      <div className={styles.blockDiscript}>
        <p className={styles.textDiscript}>{discript}</p>
      </div>
      <div
        className={styles.blockImgCraftBeer}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        ref={containerRef}
      >
        <div className={styles.blockImgConerAbs}>
          <img
            loading="lazy"
            // alt={"ImgConerAbs"}
          />
        </div>
        <img
          className={styles.imgBeerW}
          loading="lazy"
          style={{
            display: "inline-block",
            cursor: isScrolling ? "grabbing" : "grab",
          }}
          // alt={"imgBeerW"}
        />
      </div>
      <div className={styles.blockScroll}>
        <img
        // alt={"imgScroll"}
        />
      </div>
    </section>
  );
};

export { MakingBeer };
