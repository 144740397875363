import styles from "../../../styles/layout/Widgets/burger/Burger.module.scss";
import { headerContentN, dopContactN } from "../../Entities/Header/dataHeader";
import AnchorLink from "react-anchor-link-smooth-scroll";
import iconCloseR from "../../../assets/icon/header/closeW.png";

const Burger = ({ setOpenBurger, setOpenPopup }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.blockModal}>
        <div className={styles.blockClose}>
          <div onClick={() => setOpenBurger(() => false)}>
            <img src={iconCloseR} alt={"iconCloseR"} />
          </div>
        </div>
        <div className={styles.blockHeaderContent}>
          <nav className={styles.blockButtonnavBar}>
            {headerContentN.map((elem, index) => (
              <AnchorLink
                style={{ textDecoration: "none" }}
                key={index}
                href={elem?.id}
                onClick={() => setOpenBurger(() => false)}
              >
                <button className={styles.blockTextContentHeader}>
                  {elem?.titel.toLocaleUpperCase()}
                </button>
              </AnchorLink>
            ))}
          </nav>
          <nav className={styles.blockButtonnavBar}>
            {dopContactN.map((elem, index) =>
              index === 0 ? (
                <button
                  onClick={() => {
                    setOpenBurger(() => false);
                    setOpenPopup(() => true);
                  }}
                  className={styles.blockTextContentHeader}
                  key={index}
                >
                  {elem?.titel.toLocaleUpperCase()}
                </button>
              ) : (
                <AnchorLink
                  style={{ textDecoration: "none" }}
                  href={elem?.id}
                  key={index}
                >
                  <button
                    onClick={() => setOpenBurger(() => false)}
                    className={styles.blockTextContentHeader}
                  >
                    {elem?.titel.toLocaleUpperCase()}
                  </button>
                </AnchorLink>
              )
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export { Burger };
