const discript =
  "Мы аккуратно сочетаем традиционную кухню Красной Поляны, баварский подход к закускам и личные кулинарные находки";

const dataDiscriptPhoto = [
  {
    photo: "/img/AboutFood/BBQ.jpg",
    titel: "Свиные ребра BBQ",
    discript: "",
  },

  {
    photo: "/img/AboutFood/tomlen.jpg",
    titel: "Томленая голень ягненка с соусом",
    discript: "",
  },
  {
    photo: "/img/AboutFood/djerki.jpg",
    titel: "Джерки",
    discript: "собственного производства",
  },
  {
    photo: "/img/AboutFood/skymbria.jpg",
    titel: "Скумбрия горячего копчения",
    discript: "с мочеными яблоками и томатным кексом",
  },
  {
    photo: "/img/AboutFood/FileMinion.jpg",
    titel: "Филе миньон",
    discript: "с толченым картофелем и перечным соусом",
  },
];
export { discript, dataDiscriptPhoto };
