import styles from "../../../styles/layout/BlockTakeItWithYou/TakeItWithYou.module.scss";
import {
  StraightPipes,
  StraightPipesSecond,
  DoublePipesMain,
} from "../../Widgets/WidgetsPipe/Pipes";
import { dataDiscript } from "./dataTakeItWithYou";
import { CornerIcon } from "../../Widgets/WidgetsPipe/Pipes";
// import takeBeer from "/";

const TakeItWithYou = () => {
  return (
    <section className={styles.container}>
      <div className={styles.blockTitle}>
        <CornerIcon />
        <h2 className={styles.textTitel}>
          {"Берите с собой".toLocaleUpperCase()}
        </h2>
      </div>
      <div className={styles.blockDiscript}>
        <p className={styles.textDiscriptTitel}>{dataDiscript?.discript}</p>
        <div className={styles.blockDiscriptTitel}>
          <p className={styles.textDiscriptTitel}>{dataDiscript?.timeWork}</p>
        </div>
        <p className={styles.textDiscrTimeContent}>{dataDiscript?.time}</p>
      </div>
      <div className={styles.blockImgRestaurants}>
        <img
          loading="lazy"
          // src={
          //   "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/restaurants/image_979.png"
          // }
          alt={"imgTakeBeer"}
          src={"/img/TakeItWithYou/takeBeer.jpg"}
        />
      </div>
    </section>
  );
};

export { TakeItWithYou };
