import { useState } from "react";
import styles from "../../../styles/layout/Header/Header.module.scss";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {
  dopContact,
  headerContent,
  headerContentN,
  dopContactN,
} from "./dataHeader";
// Icon
import iconBurger from "../../../assets/icon/header/burger.png";
import iconMaplog from "../../../assets/icon/header/Vector.png";

const Header = ({ setOpenPopup, setOpenBurger }) => {
  // const [openBurger, setOpeneBurger] = useState(false);

  const MobileNavBar = () => {
    return (
      <div className={styles.blockShowNavbar}>
        <div
          onClick={() => setOpenBurger(() => true)}
          className={styles.blockBurger}
        >
          <img src={iconBurger} alt={"iconBurger"} />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <MobileNavBar />
      <header>
        <div className={styles.blockHeader}>
          <nav className={styles.blockTab}>
            {headerContentN.map((elem, index) => (
              <AnchorLink
                style={{ textDecoration: "none" }}
                key={index}
                href={elem?.id}
              >
                <button>{elem?.titel.toLocaleUpperCase()}</button>
              </AnchorLink>
            ))}
          </nav>
          <nav className={styles.contactBlock}>
            {dopContactN.map((elem, index) =>
              index === 0 ? (
                <button
                  key={index}
                  onClick={() => setOpenPopup(() => true)}
                  style={
                    index === 0
                      ? {
                          backgroundColor: "#E6702D",
                          minWidth: "128px",
                          justifyContent: "center",
                        }
                      : {}
                  }
                >
                  {elem?.titel.toLocaleUpperCase()}
                </button>
              ) : (
                <AnchorLink
                  style={{ textDecoration: "none" }}
                  key={index}
                  href={elem?.id}
                >
                  <button
                    style={
                      index === 0
                        ? {
                            backgroundColor: "#E6702D",
                            minWidth: "128px",
                            justifyContent: "center",
                          }
                        : {}
                    }
                  >
                    {elem?.titel.toLocaleUpperCase()}
                  </button>
                </AnchorLink>
              )
            )}
          </nav>
        </div>
      </header>
      <div className={styles.blockTitle}>
        <img
          className={styles.logoBeerBird}
          alt={"Isolation_Mode"}
          src={
            "/img/Header/Isolation_Mode.svg"
            // "https://krasnayapolyanaresort.ru/html/widgets/pub-birds/assets/isolation_mode.svg"
          }
        />
        <div className={styles.blockTitelText}>
          <h1>ПТИЦЫ ЗАХМЕЛЕЛИ</h1>
          <p>Пивоварня, безупречная кухня, уникальный вид</p>
        </div>
      </div>
      <div className={styles.blockMap}>
        <img src={iconMaplog} />
        <p className={styles.textMap}>Курорт Красная Поляна, 960 м</p>
      </div>
    </div>
  );
};

export { Header };
