const discriptPhilosophy =
  "Птицы захмелели ‒ это возможность заглянуть в закулисье пивоварения, увидеть полный цикл рождения, узнать легенду, попробовать свежесваренный напиток и кухню, идеально подобранную под наши титульные сорта";

const contentBear = [
  {
    titel: `Пиво ‒ \nцентр стола`,
    discript: "Единственный напиток, к которому подбирают блюда, а не наоборот",
  },
  {
    titel: "Пиво ‒\nточка объединения",
    discript:
      "За пивом можно обсудить то, чего нельзя обсудить за бокалом вина",
  },
  {
    titel: "Пиво ‒\nвечная классика",
    discript:
      "Оно уместно под любой повод — от дня рождения до деловой встречи",
  },
];

export { discriptPhilosophy, contentBear };
