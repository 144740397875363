const dataBar = [
  {
    titel: "Барная посадка",
    photo: "/img/Atmosphere/barS.jpg",
  },
  {
    titel: "Ресторанная посадка",
    photo: "/img/Atmosphere/restP.jpg",
  },
  {
    titel: "Сезонная видовая терраса",
    photo: "/img/Atmosphere/seasonT.jpg",
  },
  {
    titel: "Фирменный магазин",
    photo: "/img/Atmosphere/firmM.jpg",
  },
  //other /img/Atmosphere/
  {
    titel: "Быстрая уличная линия",
    photo: "/img/Atmosphere/image 980.png",
  },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 981.png",
  // },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 982.png",
  // },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 983.png",
  // },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 987.png",
  // },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 988.png",
  // },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 989.png",
  // },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 990.png",
  // },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 991.png",
  // },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 992.png",
  // },
  {
    titel: "Быстрая уличная линия",
    photo: "/img/Atmosphere/image 993.png",
  },
  {
    titel: "Быстрая уличная линия",
    photo: "/img/Atmosphere/image 994.png",
  },
  // {
  //   titel: "Быстрая уличная линия",
  //   photo: "/img/Atmosphere/image 996.png",
  // },
];

export { dataBar };
